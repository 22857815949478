import * as React from "react"

function FaConstruction() {

	return (

    <div className="back">
        
        
        
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.783 15.172L15.904 13.051L21.9 19.047L19.779 21.168L13.783 15.172ZM17.5 10C19.43 10 21 8.43003 21 6.50003C21 5.92003 20.84 5.38003 20.59 4.90003L17.89 7.60003L16.4 6.11003L19.1 3.41003C18.62 3.16003 18.08 3.00003 17.5 3.00003C15.57 3.00003 14 4.57003 14 6.50003C14 6.91003 14.08 7.30003 14.21 7.66003L12.36 9.51003L10.58 7.73003L11.29 7.02003L9.87998 5.61003L12 3.49003C11.4375 2.92823 10.675 2.61267 9.87998 2.61267C9.08497 2.61267 8.32248 2.92823 7.75998 3.49003L4.21998 7.03003L5.62998 8.44003H2.80998L2.09998 9.15003L5.63998 12.69L6.34998 11.98V9.15003L7.75998 10.56L8.46998 9.85003L10.25 11.63L2.83998 19.04L4.95998 21.16L16.34 9.79003C16.7 9.92003 17.09 10 17.5 10Z" fill="#005959"/>
</svg>


        
    </div>

    );
}

export default FaConstruction;